<template>
  <transition>
    <div v-if="show" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container saved-search-modal">
          <div class="header-lockup">
            <div class="text">
              <h4>Add Saved Search</h4>
            </div>
            <button class="Close" @click="$emit('close')">
              <span>Close</span>
            </button>
          </div>
          <SavedSearchList
            :start-with-add="true"
            @saveComplete="$emit('close')"
          />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'SavedSearchModal',
  components: {
    SavedSearchList: () => import('~/components/Cellar/SavedSearchList.vue'),
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
